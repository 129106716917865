export default {
    inserted: function (el) {
        el.dataset.guard = false;
    },
    mounted(el) {
        setTimeout(() => {
            el.dataset.guard = true;
        }, 50);
    },
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!el.dataset.guard) {
                return;
            }
            if (!(el == event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('mousedown', el.clickOutsideEvent);
    },
    // unbind
    unmounted(el) {
        el.dataset.guard = false;
        document.body.removeEventListener('mousedown', el.clickOutsideEvent);
    },
};
