import {createRouter, createMemoryHistory, createWebHistory} from 'vue-router';

const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('./pages/home/Index.vue'),
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('./pages/products/Index.vue'),
    },

    {
        path: '/policy',
        name: 'policy',
        component: () => import('./pages/policy/Index.vue'),
    },
    {path: '/:catchAll(.*)', redirect: '/'},
];

const router = createRouter({
    history,
    routes,
});

export default router;
