import {createSSRApp, createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import clickOutside from './libs/clickOutside';


import './main.css';

const isSSR = typeof window === 'undefined';

export default function build() {
    const app = isSSR ? createSSRApp(App) : createApp(App);
    app.directive('click-outside', clickOutside);

    app.use(store);
    app.use(router);

    return {app, router};
}
