<template>
    <router-view />
</template>
<style></style>
<script>
export default {
    mounted() {},
};
</script>

<style lang="scss">
a {
    cursor: pointer;
}
* {
    outline: none;
    scroll-behavior: smooth;
}

.lib-bg-orange {
    background: linear-gradient(40.17deg, #eb1b24 -26.9%, #ee3423 -11.81%, #f15622 12.49%, #f47120 37.33%, #f68420 62.57%, #f88f1f 88.49%, #f8931f 116.08%);
}
.lib-color-orange {
       color: #F47120;
}

.lib-color-darkblue {
    color: #0f0d3f;
}

.lib-bg-darkblue {
    background: linear-gradient(270.56deg, #000000 0.39%, #0f0d3f 99.47%) !important;
}

.lib-hide-scroll {
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
}

@keyframes lib-slide-in {
    0% {
        opacity: .1;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}
@keyframes lib-slide-in {
    0% {
        opacity: .1;
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes lib-slide-out {
    0% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
        opacity: .1;
    }
    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
    }
}
@-webkit-keyframes lib-slide-out {
    0% {
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        -o-transform: translateX(10px);
        transform: translateX(10px);
        opacity: .1;
    }
    100% {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
        opacity: 1;
    }
}

.lib-mobile {
    display: flex;
}
.lib-desktop {
    display: none;
}

.lib-paddings {
    padding-top: 68px;
    padding-bottom: 65px;
}

@media (min-width: 390px) {
    .lib-paddings {
        padding-top: 90px;
        padding-bottom: 75px;
    }
}

@media (min-width: 640px) {
    .lib-paddings {
        padding-top: 115px;
        padding-bottom: 93px;
    }
}

@media (min-width: 768px) {
    .lib-paddings {
        padding-top: 161px;
        padding-bottom: 126px;
    }
}

@media (min-width: 1025px) {
}

@media (min-width: 1280px) {
    .lib-paddings {
        padding-top: 130px;
        padding-bottom: 148px;
    }
}

@media (min-width: 1536px) {
    .lib-paddings {
        padding-bottom: 230px;
    }
}

@media (min-width: 1280px) {
    .lib-mobile {
        display: none;
    }
    .lib-desktop {
        display: flex;
    }
}

.lib-slide-in,
.lib-slide-in-1 {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    animation: lib-slide-out 1s forwards;
    opacity: .1;
    -webkit-animation: lib-slide-out 1s forwards;
    transition-timing-function: ease;
}

.lib-slide-out,
.lib-slide-out-1 {
    transform: translateX(-10px);
    -webkit-transform: translateX(-10px);
    opacity: .1;
    animation: lib-slide-in 1s forwards;
    -webkit-animation: lib-slide-in 1s forwards;
    transition-timing-function: ease;
}

.lib-email-obfuscation {
    padding: 0 !important;
    flex-direction: row !important;
    display: inline !important;
    flex-wrap: nowrap !important;
    width: auto;
    row-gap: 0px !important;
    word-break: break-all;
    li {
        display: inline !important;
    }
    li.hidden {
        display: none !important;
    }
}

.container-1 {
    position: relative;
    padding-right: 27.09px !important;
    padding-left: 27.09px !important;
}

@media (min-width: 390px) {
    .container-1 {
        padding-right: 34.12px !important;
        padding-left: 34.12px !important;
    }
}

@media (min-width: 640px) {
    .container-1 {
        padding-right: 66px !important;
        padding-left: 66px !important;
    }
}

@media (min-width: 768px) {
    .container-1 {
        padding-right: 78.61px !important;
        padding-left: 78.61px !important;
    }
}

@media (min-width: 1025px) {
    .container-1 {
        padding-right: 129px !important;
        padding-left: 129px !important;
    }
}
@media (min-width: 1366px) {
    .container-1 {
        // padding-right: 405px;
        // padding-left: 405px;
    }
}

@media (min-width: 1536px) {
    .container-1 {
        // max-width: 784px;
    }
}

.content-max-width {
    max-width: 1102px !important;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1280px) {
    .content-max-width-xl {
        max-width: 1102px !important;
        margin-right: auto;
        margin-left: auto;
    }
}
</style>
