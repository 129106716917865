import {createStore, createLogger} from 'vuex';

let lock;
export default createStore({
    state: () => ({
        showMore: false,
        productPage: 1,
        productSlide: '',
        screenWidth: typeof window !== 'undefined' ? window.innerWidth : null,
        fullscreen: '',
        projects: [
            {name: 'project1', image: '1.jpeg', id: 1},
            {name: 'project2', image: '2.jpeg', id: 2},
            {name: 'project3', image: '3.jpeg', id: 3},
            {name: 'project4', image: '4.jpeg', id: 4},
        ],
    }),

    mutations: {
        toggleProducts(state, payload) {
            if (payload === null || payload === undefined) {
                payload = !state.showMore;
            }
            state.showMore = payload;
        },
        setProductPage(state, payload) {
            state.productPage = payload;
        },

        setFullScreen(state, payload) {
            state.fullscreen = payload;
        },

        setProductSlide(state, payload) {
            clearTimeout(lock);
            lock = setTimeout(() => {
                state.productSlide = payload;
            }, 0);
        },

        setScreenWidth(state, payload) {
            state.screenWidth = window.innerWidth;
        },
    },

    plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
});
